import React from 'react';
import { useMonth } from '@datepicker-react/hooks';

import Day from './Day';
import NavButton from './NavButton';
import ArrowLeft from '../../../assets/img/icons/ic-arrow-left.svg';
import ArrowRight from '../../../assets/img/icons/ic-arrow-right.svg';

import styles from '../Calendar.module.scss';

const Month = ({
  year,
  month,
  firstDayOfWeek,
  goToPreviousMonths,
  goToNextMonths,
}) => {
  const {
    days,
    weekdayLabels,
    monthLabel,
  } = useMonth({
    year,
    month,
    firstDayOfWeek,
  });

  return (
    <div>
      <div className="flex flex-between flex-v-center">
        <NavButton onClick={goToPreviousMonths}>
          <ArrowLeft />
        </NavButton>
        <div className={styles.periodTitle}>{monthLabel}</div>
        <NavButton onClick={goToNextMonths}>
          <ArrowRight />
        </NavButton>
      </div>
      <div className={`${styles.weekDays} ${styles.weekDaysTitles}`}>
        {weekdayLabels.map((dayLabel) => (
          <div key={dayLabel}>
            {dayLabel}
          </div>
        ))}
      </div>
      <div className={styles.weekDays}>
        {days.map(({ dayLabel, date }, index) => {
          if (dayLabel) {
            return (
              <Day
                date={date}
                key={date.toString()}
                dayLabel={dayLabel}
              />
            );
          }

          return <div key={index} />;
        })}
      </div>
    </div>
  );
};

export default Month;
