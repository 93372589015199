import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDatepicker, START_DATE } from '@datepicker-react/hooks';

import DatepickerContext from '../context';
import Month from './Month';
import {
  END_DATE_INDEX,
  START_DATE_INDEX,
  SEARCH_DATE_MAX_DAYS,
  dayInMilliSeconds,
} from '../../../constans/datepicker';
import StorageService from '../../../services/storage.service';
import { LOCALSTORAGE_KEYS } from '../../../constans/localstorage';

function Datepicker({
  setFieldValue,
  fields,
  checkin,
  checkout,
  children,
  saveToStore = false,
  untilTommorow = false,
}) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState(START_DATE);
  const storageService = StorageService();

  useEffect(() => {
    if (startDate) {
      const date = new Date(startDate);
      date.setDate(date.getDate() + SEARCH_DATE_MAX_DAYS);
    }
  }, [startDate]);

  const updateDateInStorage = (dates) => {
    if (!saveToStore) return;

    const transformedDates = {
      checkin: moment(dates.checkin).format('YYYY-MM-DD'),
      checkout: moment(dates.checkout).format('YYYY-MM-DD'),
    };
    storageService.set(LOCALSTORAGE_KEYS.SEARCH_DATES, transformedDates);
  };

  const updateStartDate = (date) => {
    setStartDate(date);
    setFieldValue(fields[START_DATE_INDEX], moment(date).format('YYYY-MM-DD'));
  };

  const updateEndDate = (date) => {
    setEndDate(date);

    setFieldValue(fields[END_DATE_INDEX], moment(date).format('YYYY-MM-DD'));
  };

  const handleDateChange = ({
    focusedInput: focus,
    startDate: start,
    endDate: end,
  }) => {
    if (!focus) {
      setFocusedInput(START_DATE);

      updateStartDate(start);
      updateEndDate(end);

      updateDateInStorage({
        checkin: start,
        checkout: end,
      });

      return;
    }

    updateStartDate(start);
    updateEndDate(end);

    updateDateInStorage({
      checkin: start,
      checkout: end,
    });

    if (!end && start) {
      const date = new Date(start);
      date.setDate(date.getDate() + 1);
      updateEndDate(date);

      updateDateInStorage({
        checkin: start,
        checkout: date,
      });
    }

    setFocusedInput(focus);
  };

  const daysDuration = untilTommorow
    ? dayInMilliSeconds * 2
    : dayInMilliSeconds;

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    numberOfMonths: 1,
    startDate,
    endDate,
    focusedInput,
    onDatesChange: handleDateChange,
    minBookingDate: new Date(Date.now() + daysDuration),
    // eslint-disable-next-line max-len
    maxBookingDate: new Date(
      new Date().getFullYear() + 1,
      new Date().getMonth(),
      new Date().getDate()
    ),
  });

  useEffect(() => {
    if (checkin) {
      updateStartDate(moment(checkin).toDate());
    }

    if (checkout) {
      updateEndDate(moment(checkout).toDate());
    }
  }, []);

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        startDate,
        endDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
      }}
    >
      <div>
        {activeMonths.map((month) => (
          <Month
            goToPreviousMonths={goToPreviousMonths}
            goToNextMonths={goToNextMonths}
            key={`${month.year}-${month.month}`}
            year={month.year}
            month={month.month}
            firstDayOfWeek={firstDayOfWeek}
          />
        ))}
        {children}
      </div>
    </DatepickerContext.Provider>
  );
}

export default Datepicker;
