import React from 'react';

export const datepickerContextDefaultValue = {
  focusedDate: null,
  startDate: null,
  endDate: null,
  isDateFocused: () => false,
  isDateSelected: () => false,
  isDateHovered: () => false,
  isDateBlocked: () => false,
  isFirstOrLastSelectedDate: () => false,
  onDateFocus: () => { },
  onDateHover: () => { },
  onDateSelect: () => { },
};

export default React.createContext(datepickerContextDefaultValue);
