import React, { useRef, useContext } from 'react';
import { useDay } from '@datepicker-react/hooks';

import DatepickerContext from '../context';
import styles from '../Calendar.module.scss';

const Day = ({ dayLabel, date }) => {
  const dayRef = useRef(null);

  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
    startDate,
    endDate,
  } = useContext(DatepickerContext);

  const isOneDay = startDate === endDate;

  const {
    isSelected,
    isSelectedStartOrEnd,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });
  if (!dayLabel) {
    return (<div />);
  }

  return (
    <button
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={tabIndex}
      type="button"
      ref={dayRef}
      className={`
        ${styles.day}
        ${isSelected ? styles.selected : ''}
        ${isSelectedStartOrEnd ? styles.selectedStartOrEnd : ''}
        ${isOneDay && styles.selectedSingleSelect}
        ${disabledDate ? styles.dayDisabled : ''}
      `}
    >
      {dayLabel}
    </button>
  );
};

export default Day;
