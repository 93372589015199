import React from 'react';
import styles from '../Calendar.module.scss';

const NavButton = ({ children, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={styles.navButton}
  >
    {children}
  </button>
);

export default NavButton;
